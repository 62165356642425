<template>
  <div class="foot">
    <div class="foot_link flex flex-between">
      <div class="phone flex flex-direction-column">
        <section class="flex flex-align-center">
          <img src="~@/assets/u8/icon-phone.png" alt="" />
          <span>联系电话：400-800-3550/0311-85231868</span>
        </section>
        <div class="flex flex-direction-column">
          <div class="link flex flex-align-center">
            <img src="~@/assets/u8/icon-link.png" alt="" />
            <span>企业网址：www.chinau8.cn</span>
          </div>
          <div class="address flex flex-direction-column">
            <div class="flex flex-align-center">
              <img src="~@/assets/u8/icon-address.png" alt="" />
              <span
                >公司地址：金华市金东区多湖街道光南路广润瀚城北门四幢120室</span
              >
            </div>
            <div class="text-indent">
              哈尔滨市南岗区学府路191号创业孵化产业园L2一楼
            </div>
            <div class="text-indent">
              石家庄市桥西区中华南大街485号一江大厦1710
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="flex code">
          <div
            class="code-box flex flex-direction-column flex-align-center flex-justify-content"
          >
            <img src="~@/assets/u8/code1.png" alt="" />
            <span>【游巴网公众号】</span>
          </div>
          <div
            class="code-box flex flex-direction-column flex-align-center flex-justify-content"
          >
            <img src="~@/assets/u8/code2.png" alt="" />
            <span>【游巴出行-小程序】</span>
          </div>
        </div>
      </div>
    </div>
    <div class="ICP">
      <div>www.chinau8.cn 技术运营 游巴网</div>
      <img src="../../assets/homepage/insignia.png" />
      <div>
        <span
          ><a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33070302100119"
            target="_blank"
            >浙公网安备 33070302100222号</a
          ></span
        >
        <span
          ><a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            >备案许可证号：浙ICP备19043441号
          </a>
        </span>
        <span
          ><a href="https://www.miit.gov.cn" target="_blank">
            中华人民共和国工业和信息化部</a
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot-bar",
  components: {},
  data() {
    return {};
  },

  mounted() {},
  methods: {
    goNews(category) {
      this.$router.push({
        name: "news",
        query: {
          categoryId: category,
        },
      });
    },
    goAbout(category) {
      this.$router.push({
        name: "aboutUs",
        query: {
          activeTab: category,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.foot {
  width: 1920px;
  // margin-top: 20px;
  position: relative;
  box-sizing: border-box;

  .foot_link {
    width: 100%;
    box-sizing: border-box;
    height: 300px;
    background: url("https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/footer-bg.png")
      no-repeat;
    background-size: contain;
    padding: 63px 300px 0 270px;
    .phone {
      color: #fff;
      font-size: 20px;
      align-items: start;

      img {
        width: 24px;
        margin-right: 5px;
      }

      section {
        margin-bottom: 22px;
      }
    }

    .link {
      color: #fff;
      font-size: 20px;
      margin-bottom: 22px;

      img {
        width: 24px;
        margin-right: 5px;
      }
    }

    .address {
      color: #fff;
      font-size: 20px;
      align-items: start;

      img {
        width: 24px;
        margin-right: 5px;
      }

      div {
        margin-bottom: 20px;
      }
      .text-indent {
        text-indent: 130px;
      }
    }

    .right {
      color: rgba(255, 255, 255, 1);
      font-size: 14px;

      .code {
        .code-box {
          margin-right: 60px;

          img {
            width: 180px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .ICP {
    background-color: rgba(3, 5, 7, 1);
    width: 100%;
    height: 60px;
    font-size: 14px;
    color: rgba(187, 187, 187, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-top: -1px;

    img {
      width: 20px;
      height: 20px;
      margin: 0px 6px 0px 20px;
    }

    span {
      margin-right: 20px;
    }

    a {
      font-size: 14px;
      color: rgba(187, 187, 187, 1);
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
