import { render, staticRenderFns } from "./foot-bar.vue?vue&type=template&id=1db04914&scoped=true&"
import script from "./foot-bar.vue?vue&type=script&lang=js&"
export * from "./foot-bar.vue?vue&type=script&lang=js&"
import style0 from "./foot-bar.vue?vue&type=style&index=0&id=1db04914&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1db04914",
  null
  
)

export default component.exports